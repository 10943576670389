import { Controller } from "@hotwired/stimulus"
// import datatables
import DataTable from "datatables.net-bs5"
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import { event } from "jquery";


// Connects to data-controller="teams-datatable"
export default class extends Controller {
  connect() {
    if ($(this.element).find('.dt-teams').length > 0) {
      let dt = $(this.element).find('.dt-teams').DataTable({
        dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end"f>>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
        pagingType: "simple",
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
        stateSave: true,
        stateSaveParams: function (settings, data) {
          data.columns[2].visible = false;
        },
        responsive: true,
        drawCallback: function () {
          $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
          $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");
        },
        language: {
          emptyTable: $(".table").data("empty-table"),
          info: $(".table").data("info"),
          infoEmpty: $(".table").data("info-empty"),
          infoFiltered: $(".table").data("info-filtered"),
          lengthMenu: 'Show _MENU_',
          zeroRecords: $(".table").data("zero-records"),
          paginate: {
            previous: "<i class='bi bi-chevron-left'></i>",
            next: "<i class='bi bi-chevron-right'></i>"
          }
        },
        columnDefs: [
          { orderable: false, targets: -1 },
          { className: "d-flex justify-content-end", targets: -1 },
          { visible: false, targets: 2 },
        ],
        initComplete: function () {
          $(".table thead th:last-child").removeClass("d-flex justify-content-end");
          $(".dataTables_info").addClass("pt-0");

          let filterValue1 = localStorage.getItem('filterValue1');
          if (filterValue1) {
            $('#filterSelect1').val(filterValue1);
            this.api().column(2).search(filterValue1).draw();
          }
        }
      });
      if (dt) {
        $(this.element).find(".dataTables_info").addClass("pt-0");
        window.dt = dt;

        // Unload datatable when turbolinks visit
        $(document).on('turbo:before-visit', function () {
          if ($.fn.dataTable.isDataTable('.dt-teams')) {
            let dt = $('.dt-teams').DataTable();
            dt.destroy();
          }
        });

        document.addEventListener('turbo:before-cache', function () {
          if ($.fn.dataTable.isDataTable('.dt-teams')) {
            let dt = $('.dt-teams').DataTable();
            dt.state.save();
            dt.column(2).search("").draw();
          }
        });


      }
    }
  }
  filter(event) {
    this.filterValue1 = event.target.value;
    localStorage.setItem('filterValue1', this.filterValue1);
    if (this.filterValue1 !== "") {
      window.dt.column(2).search(this.filterValue1).draw();
    } else {
      window.dt.column(2).search("").draw();
    }
  }

  filterTable() {
    if (this.filterValue1 && this.filterValue1.trim() !== "") {
      window.dt.column(2).search(this.filterValue1).draw();
    }
  }
  clearFilter() {
    this.filterValue1 = "";
    window.dt.column(2).search("").draw();
  }

}
