import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const map_select = this.element.querySelector("#map-select-wrapper")
    const pdf_upload = this.element.querySelector("#pdf-upload-wrapper")

    if (map_select && !pdf_upload) {
      map_select.style.display = "block"
    } else if (!map_select && pdf_upload) {
      pdf_upload.style.display = "block"
    } else if (map_select && pdf_upload) {
      const attachmentSelect = this.element.querySelector("#attachment_type")

      if (attachmentSelect.value === "map") {
        map_select.style.display = "block"
        pdf_upload.style.display = "none"
      } else {
        map_select.style.display = "none"
        pdf_upload.style.display = "block"
      }

      attachmentSelect.addEventListener("change", function () {
        if (attachmentSelect.value === "map") {
          map_select.style.display = "block"
          pdf_upload.style.display = "none"
        } else if (attachmentSelect.value === "pdf") {
          map_select.style.display = "none"
          pdf_upload.style.display = "block"
        }
      })
    }
  }
}
